import {REMOVE_GUEST_USER, REMOVE_USER, SET_UNFILTERED_USERS, UPDATE_USER} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';
import _ from "lodash";

export default function unfilteredUserListReducer(state = initialState.unfilteredUserList, action) {
    let newState;
    let index;
    switch (action.type) {
        case SET_UNFILTERED_USERS:
            newState = objectAssign({}, state);
            newState = action.data;
            return newState;
        case UPDATE_USER:
            index = _.findIndex(state, {id: action.data.id});
            if (index > -1) {
                newState = objectAssign({}, state);
                return _.map(newState, (current) => {
                    if (current.id === action.data.id) {
                        return _.assign({}, current, action.data);
                    } else {
                        return current;
                    }
                });
            } else {
                if (_.has(action.data, 'first_name')) {
                    return [...state, _.assign({}, {present: false}, action.data, {isConferenceUser: action.data.user_type === 'ConferenceGuest'})];
                }
                return state;
            }
        case REMOVE_GUEST_USER:
            const guestIndex = _.findIndex(state, {id: action.data.id, is_guest: true});
            if (guestIndex > -1) {
                return [
                    ...state.slice(0, guestIndex), ...state.slice(guestIndex + 1)
                ];
            } else {
                return state;
            }

            return;

        case REMOVE_USER:
            index = _.findIndex(state, {id: action.data.id});
            if (index > -1) {
                return [
                    ...state.slice(0, index), ...state.slice(index + 1)
                ];
            } else {
                return state;
            }

        default:
            return state;

    }
}
