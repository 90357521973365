import {SET_ELEVATOR_LETTERS_ENABLED, SET_WAITING_ROOM_ENABLED} from '../constants/actionTypes';
import initialState from './initialState';
import _ from "lodash";
export default function elevatorLettersEnabledReducer(state = initialState.waitingRoomEnabled, action) {
  switch (action.type) {
    case SET_ELEVATOR_LETTERS_ENABLED:
      return _.get(action, 'elevatorLettersEnabled', true);
    default:
      return state;
  }
}
