import {SHOW_TOUR} from '../constants/actionTypes';
import initialState from './initialState';

export default function adminToolReducer(state = initialState.showTour, action) {

    switch (action.type) {
        case SHOW_TOUR:
            return action.show;
        default:
            return state;
    }
}
