import {SET_WAITING_ROOM_ENABLED} from '../constants/actionTypes';
import initialState from './initialState';
import _ from "lodash";

export default function conferenceEnabledReducer(state = initialState.waitingRoomEnabled, action) {
  switch (action.type) {
    case SET_WAITING_ROOM_ENABLED:
      return _.get(action, 'waitingRoomEnabled', true);
    default:
      return state;
  }
}
