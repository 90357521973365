import {ROOMS_LOADED, UPDATE_ROOM, ROOMS_ADDED, DELETE_ROOMS, DELETE_ROOMS_BY_ID} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';
import _ from "lodash";

export default function roomListReducer(state = initialState.rooms, action) {
  let newState;

  switch (action.type) {
    case ROOMS_LOADED:
      newState = objectAssign({}, state);
      newState = action.data;
      return newState;
    case ROOMS_ADDED:
      newState = objectAssign({}, state);

      let roomsToAdd = action.data;
      _.map(newState, current=> {
        const index = _.findIndex(roomsToAdd, { id: current.id });
        if(_.findIndex(roomsToAdd, { id: current.id }) > -1) {
          roomsToAdd.splice(index,1);
        }
      });

      return [...state, ...roomsToAdd];
    case UPDATE_ROOM:
      return _.map(state, (current) => {
        if (current.id === action.data.id) {
          return _.assign({}, current, action.data);
        } else {
          return current;
        }
      });
    case DELETE_ROOMS_BY_ID:
      return _.chain(state)
          .map(current => _.indexOf(action.data.roomIds, current.id) > -1 ? null : current)
          .compact()
          .value();

    case DELETE_ROOMS:
      const floorId = action.data.id;
      return _.chain(state)
        .map(current => current.floor_id !== floorId ? current : null)
        .compact()
        .value();
    default:
      return state;
  }
}
