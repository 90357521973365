import {REMOVE_FLOOR, SET_UNFILTERED_FLOORS, UPDATE_BACKUP_FLOOR, UPDATE_FLOOR} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';
import _ from "lodash";


export default function unfilteredFloorsReducer(state = initialState.unfilteredFloors, action) {
    let newState;
    let floorIndex;
    switch (action.type) {
        case SET_UNFILTERED_FLOORS:
            newState = objectAssign({}, state);
            newState = action.data;
            return newState;
        case UPDATE_BACKUP_FLOOR:
            floorIndex = _.findIndex(state, {id: action.data.id});
            if (floorIndex > -1) {
                newState = objectAssign({}, state);
                return _.map(newState, (current) => {
                    if (current.id === action.data.id) {
                        return _.assign({}, current, action.data);
                    } else {
                        return current;
                    }
                });
            } else {
                return [...state, action.data];
            }

            return;
        case UPDATE_FLOOR:
            floorIndex = _.findIndex(state, {id: action.data.id});
            if (floorIndex > -1) {
                newState = objectAssign({}, state);
                return _.map(newState, (current) => {
                    if (current.id === action.data.id) {
                        return _.assign({}, current, action.data);
                    } else {
                        return current;
                    }
                });
            } else {
                // add the floor - TODO: decorate with conference floor property, determine why empty floors get added
                if (action.data.level > 100) {
                    action.data.conference = true;
                    action.data.level = state.length + 2;
                }
                return state.concat(action.data);
            }

            return;
        case REMOVE_FLOOR:
            floorIndex = _.findIndex(state, {id: action.data.id});
            if (floorIndex > -1) {
                return [
                    ...state.slice(0, floorIndex), ...state.slice(floorIndex + 1)
                ];
            } else {
                return state;
            }
        default:
            return state;

    }
}
