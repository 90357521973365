import {
    CONFERENCES_FETCHED,
    UPDATE_CONFERENCE,
    UPDATE_SESSION,
    DELETE_CONFERENCE,
    SESSION_DESTROY, PARTNER_PARTIES_FETCHED, UPDATE_PARTNER_PARTY, DELETE_PARTNER_PARTY,
} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';
import _ from "lodash";

export default function partnerPartyListReducer(state = initialState.partnerPartyList, action) {
    let newState;
    let partyIndex;
    switch (action.type) {
        case PARTNER_PARTIES_FETCHED:
            // newState = objectAssign({}, state);
            newState = action.data;
            return newState;
        case UPDATE_PARTNER_PARTY:
            partyIndex = _.findIndex(state, {id: action.data.id});
            if (partyIndex > -1) {
                newState = objectAssign({}, state);
                return _.map(newState, (current) => {
                    if (current.id === action.data.id) {
                        return _.assign({}, current, action.data);
                    } else {
                        return current;
                    }
                });
            } else {
                return [...state, action.data];
            }
        case DELETE_PARTNER_PARTY:
            partyIndex = _.findIndex(state, {id: action.data.id});
            if (partyIndex > -1) {
                return [
                    ...state.slice(0, partyIndex), ...state.slice(partyIndex + 1)
                ];
            } else {
                return state;
            }
        default:
            return state;
    }
}
