import {combineReducers} from 'redux';
import userList from './userListReducer';
import rooms from './roomListReducer';
import currentUserId from './currentUserReducer';
import meetingConfig from './meetingConfigReducer';
import maxOccupancy from './maxOccupancyReducer';
import themes from './themeReducer';
import floors from './floorsReducer';
import departments from './departmentsReducer';
import floorPlanTransforms from './floorPlanTransformReducer';
import currentFloorId from './currentFloorReducer';
import adminDisplay from './adminToolReducer';
import adminOnlyMode from './adminOnlyModeReducer';
import selfRegistration from './selfRegistrationReducer';
import backUpFloorId from './backupFloorReducer';
import sidePanelSearchTerm from './sidePanelSearchTermReducer';
import conferenceList from './conferenceListReducer';
import partnerPartyList from './partnerPartyListReducer';
import unfilteredFloors from './unfilteredFloorsReducer';
import unfilteredUserList from './unfilteredUserListReducer';

import conferenceEnabled from './conferenceEnabledReducer';
import showTour from './tourReducer'
import noPopup from './noPopUpReducer'
import missedNotificationCount from './missedNotificationsReducer'
import waitingRoomEnabled from './waitingRoomEnabledReducer';
import nativeNotifications from './nativeNotificationsReducer';
import {connectRouter} from 'connected-react-router';
import elevatorLettersEnabled from './elevatorLettersEnabledReducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  userList,
  currentUserId,
  rooms,
  meetingConfig,
  themes,
  floorPlanTransforms,
  maxOccupancy,
  floors,
  departments,
  currentFloorId,
  backUpFloorId,
  adminDisplay,
  adminOnlyMode,
  selfRegistration,
  sidePanelSearchTerm,
  conferenceList,
  unfilteredFloors,
  unfilteredUserList,
  conferenceEnabled,
  showTour,
  noPopup,
  missedNotificationCount,
  waitingRoomEnabled,
  nativeNotifications,
  elevatorLettersEnabled,
  partnerPartyList
});

export default createRootReducer;
