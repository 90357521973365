import axios from 'axios';
import {showErrorNotification} from './notifications';
import _ from 'lodash';
import Cookies from 'universal-cookie';
export default {
    setupInterceptors: () => {
        // Add a response interceptor
        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            console.log(JSON.stringify(error));
            const errorData = _.get(error, 'response.data')?.errors;
            if(_.includes(errorData, 'clear cookies')> -1) {
                const cookies = new Cookies();
                cookies.remove('_qube_session');
                window.location.reload();
            }
            else if (_.get(error, 'response.status') === 404) {
                console.log('reload on 404');
                window.location.reload();
            } else {
                if (_.get(error, 'response.status') === 401) {
                    console.log('401 in interceptor');
                }

                showErrorNotification(_.get(error, 'response.data.errors', 'Something went wrong.'));
            }

            return Promise.reject(error.response);
        });
    }
};
