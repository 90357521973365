import {ADD_NATIVE_NOTIFICATION} from '../constants/actionTypes';
import initialState from './initialState';

export default function nativeNotificationsReducer(state = initialState.nativeNotifications, action) {
    switch (action.type) {
        case ADD_NATIVE_NOTIFICATION:
            return [...state, action.data];
        default:
            return state;
    }
}
