import {SET_CONFERENCES_ENABLED} from '../constants/actionTypes';
import initialState from './initialState';
import _ from "lodash";

export default function conferenceEnabledReducer(state = initialState.conferenceEnabled, action) {
    switch (action.type) {
        case SET_CONFERENCES_ENABLED:
            return _.get(action, 'conferenceEnabled', false);
        default:
            return state;
    }
}
