import {
    CONFERENCES_FETCHED,
    UPDATE_CONFERENCE,
    UPDATE_SESSION,
    DELETE_CONFERENCE,
    SESSION_DESTROY
} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';
import _ from "lodash";

export default function conferenceListReducer(state = initialState.conferenceList, action) {
    let newState;
    let conferenceIndex;
    switch (action.type) {
        case CONFERENCES_FETCHED:
            // newState = objectAssign({}, state);
            newState = action.data;
            return newState;
        case UPDATE_CONFERENCE:
            conferenceIndex = _.findIndex(state, {id: action.data.id});
            if (conferenceIndex > -1) {
                newState = objectAssign({}, state);
                return _.map(newState, (current) => {
                    if (current.id === action.data.id) {
                        return _.assign({}, current, action.data);
                    } else {
                        return current;
                    }
                });
            } else {
                return [...state, action.data];
            }
        case UPDATE_SESSION:
            // session must belong to a conference:
            conferenceIndex = _.findIndex(state, {id: action.data.conference_id});
            if (conferenceIndex > -1) {
                newState = objectAssign({}, state);
                return _.map(newState, (current) => {
                    if (current.id === action.data.conference_id) {
                        // add the sessiom or update it
                        const sessionIndex = _.findIndex(current.sessions, {id: action.data.id})
                        if (sessionIndex > -1) {
                            const updatedSessions = _.map(current.sessions, (session) => {
                                if (session.id === action.data.id) {
                                    return _.assign({}, session, action.data);
                                } else {
                                    return session;
                                }
                            });
                            current = _.assign({}, current, {sessions: updatedSessions});
                        } else {
                            const existingSessions = !_.isEmpty(current.sessions) ? _.cloneDeep(current.sessions) : [];
                            current = _.assign({}, current, {sessions: [...existingSessions, action.data]});
                        }
                        return _.assign({}, current);
                    } else {
                        return current;
                    }
                });
            } else {
                return state;
            }
        case SESSION_DESTROY:
            // find the conference the session belongs to:
            conferenceIndex = _.findIndex(state, {id: action.data.conference_id});
            if (conferenceIndex > -1) {
                newState = objectAssign({}, state);
                return _.map(newState, (current) => {
                    if (current.id === action.data.conference_id) {
                        // delete the session
                        const sessionIndex = _.findIndex(current.sessions, {id: action.data.id})
                        if (sessionIndex > -1) {
                            const updatedSessions = [...current.sessions.slice(0, sessionIndex), ...current.sessions.slice(sessionIndex + 1)];
                            current = _.assign({}, current, {sessions: updatedSessions});
                        } else {
                            const existingSessions = !_.isEmpty(current.sessions) ? _.cloneDeep(current.sessions) : [];
                            current = _.assign({}, current, {sessions: [...existingSessions, action.data]});
                        }
                        return _.assign({}, current);
                    } else {
                        return current;
                    }
                });
            } else {
                return state;
            }
        case DELETE_CONFERENCE:
            conferenceIndex = _.findIndex(state, {id: action.data.id});
            if (conferenceIndex > -1) {
                return [
                    ...state.slice(0, conferenceIndex), ...state.slice(conferenceIndex + 1)
                ];
            } else {
                return state;
            }
        default:
            return state;
    }
}
