import {NO_POPUPS} from '../constants/actionTypes';
import initialState from './initialState';

export default function noPopUpReducer(state = initialState.noPopup, action) {

    switch (action.type) {
        case NO_POPUPS:
            return !state;
        default:
            return state;
    }
}
