import {
    INCREMENT_NOTIFICATION_COUNT,
    RESET_NOTIFICATION_COUNT,
    DECREMENT_NOTIFICATION_COUNT
} from '../constants/actionTypes';
import initialState from './initialState';
import _ from 'lodash';

export default function missedNotificationsReducer(state = initialState.missedNotificationCount, action) {

    switch (action.type) {
        case INCREMENT_NOTIFICATION_COUNT:

            const incremented = [...state, action.requestId];
            return incremented;
        case DECREMENT_NOTIFICATION_COUNT:
            const index = _.indexOf(state, action.requestId);
            if (index > -1) {
                const clone = _.clone(state);
                _.pullAt(clone, index);
                return clone;
            } else {
                return state;
            }
        case RESET_NOTIFICATION_COUNT:
            return [];
        default:
            return state;
    }
}
